import React, { useState, useEffect } from 'react';
import { BeatLoader } from 'react-spinners';
import './ResultComponent.css';

const ResultComponent = ({ title, imageFile, endpoint, color, statusMessages, updateLoadingStatus, calculateTimeTaken, index}) => {
  const [loading, setLoading] = useState(false);
  const [predictions, setPredictions] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [, setMessageIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const startTime = new Date().getTime();
      setLoading(true);
      const formData = new FormData();
      formData.append('image', imageFile);

      try {
        const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
          method: 'POST',
          body: formData,
        });
        
        const data = await response.json();
        setPredictions(data.predictions);
        setLoading(false);
        if (updateLoadingStatus) {
          updateLoadingStatus();
        }
        const endTime = new Date().getTime();
        if (calculateTimeTaken) {
          calculateTimeTaken(index, (endTime - startTime) / 1000);
        } 
      } catch (error) {
        console.error('Error:', error);
        setLoading(false);
      }
    };

    if (imageFile) {
      fetchData();
    }
  }, [imageFile, endpoint]);

  // Loop through status messages
  useEffect(() => {
    let intervalId;
    if (loading) {
      setStatusMessage(statusMessages[0]); // Start with the first message immediately
      intervalId = setInterval(() => {
        setMessageIndex(prevIndex => {
          const newIndex = (prevIndex + 1) % statusMessages.length;
          setStatusMessage(statusMessages[newIndex]);
          return newIndex;
        });
      }, 500);
    } else {
      clearInterval(intervalId);
      setStatusMessage('');
      setMessageIndex(0);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [loading, statusMessages]);

  return (
    <div className='result-component'>
      <h4>{title}</h4>
      {loading ? 
        <div className='loader-container'>
          <BeatLoader size={15} color={'#4caf50'} loading={loading} />
          <p className='white-text'>{statusMessage}</p>
        </div>
        : 
        <>
          <ul>
            {predictions && predictions.map((prediction, index) => (
              <li key={index}>
                {prediction.text}: {(prediction.probability * 100).toFixed(0)}%
                <div className="prediction-progress" style={{width: `${prediction.probability * 100}%`, backgroundColor: color}} />
              </li>
            ))}
          </ul>
        </>
      }
    </div>
  );
}

export default ResultComponent;
