import './App.css';
import Header from './components/Header/Header.js';
import Banner from './components/Banner/Banner';
import Footer from './components/Footer/Footer.js';
import ImageUploader from './components/ImageUploader/ImageUploader';
// Import the feedback page
import FeedbackPage from './pages/FeedbackPage/FeedbackPage';
import FeedbackPage2 from './pages/FeedbackPage2/FeedbackPage2';
// Import analytics from vercel
import { Analytics } from '@vercel/analytics/react'
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from 'react-helmet';

function App() {
  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + 'wake_up/')
    .then(response => response.json())
    .then(data => console.log(data))
    .catch(error => console.error(error));
  }, []);

  return (
    // Wrap everythin in a Router
    <Router>
      <div className="App">
        <Helmet>
          <title>HotCheck: Discover Your Hotness Rating</title>
          <meta name="description" content="Upload a photo and find out how alluring you are!" />
          <meta name="keywords" content="hotness, allure, photo analysis, beauty check, how beautiful am i, photo beauty rank" />
        </Helmet>
        {/* Use the banner if needed */}
        <Banner text='New Updates 11/9/23: Added the so much requested Style Factor!' />
        {/* Use the header component */}
        <Header />
        {/* Insert the analytics component */}
        <Analytics />
        <main>
          <Routes>
            <Route path="/" element={<ImageUploader />} />
            <Route path="/feedback" element={<FeedbackPage/>} />
            <Route path="/feedback2" element={<FeedbackPage2/>} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
