import React from 'react';
// Import the css
import './ShareComponent.css';

const ShareComponent = ({ shouldScintillate }) => {

  const handleShareTwitter = async () => {
    let data = {
      shareType: 'TWITTER',
      browser: navigator.userAgent,
      location: null,
    };

    try {
      const response = await fetch('https://ipinfo.io/json');
      const ipinfo = await response.json();
      data.location = {
        city: ipinfo.city,
        country: ipinfo.country,
        region: ipinfo.region,
        postal: ipinfo.postal,
        timezone: ipinfo.timezone,
      };
    } catch (error) {
      // Handle error, if any, but still proceed
    }

    await fetch(process.env.REACT_APP_API_URL + 'submit_share/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const siteURL = "https://hotcheck.vercel.app";
    const message = "Discover your rating at Hotcheck!";
    const twitterURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}&url=${encodeURIComponent(siteURL)}`;
    window.open(twitterURL, '_blank');
  };

  const handleShareWhatsApp = async () => {
    // Initialize data object
    const data = {
      shareType: 'WHATSAPP',
      browser: navigator.userAgent,
      location: null,
    };
  
    // Fetch GeoIP information
    try {
      const response = await fetch('https://ipinfo.io/json');
      const ipinfo = await response.json();
      data.location = {
        city: ipinfo.city,
        country: ipinfo.country,
        region: ipinfo.region,
        postal: ipinfo.postal,
        timezone: ipinfo.timezone,
      };
    } catch (error) {
      console.error("Couldn't fetch GeoIP information:", error);
    }
  
    // Make the API call to your backend
    fetch(process.env.REACT_APP_API_URL + 'submit_share/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  
    // Call the separate function for WhatsApp sharing
    openWhatsApp();
  };
  
  const openWhatsApp = () => {
    const siteURL = "https://hotcheck.vercel.app";
    const message = "Discover your rating at Hotcheck!";
    const whatsAppURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(`${siteURL} ${message}`)}`;
  
    // Open the WhatsApp URL
    window.open(whatsAppURL, '_blank');
  };

  return (
    <div className="share-button-container">
      <button className={`share-button-whatsapp ${shouldScintillate ? 'scintillate' : ''}`} onClick={handleShareWhatsApp}>
        <i className="fab fa-whatsapp share-button-logo"></i>
        <strong> Share on WhatsApp </strong>
      </button>
      <button className={`share-button-twitter ${shouldScintillate ? 'scintillate' : ''}`} onClick={handleShareTwitter}>
        <i className="fab fa-twitter share-button-logo"></i>
        <strong> Share on Twitter </strong>
      </button>
    </div>
  );
};

export default ShareComponent;

