import React from 'react';
import './FeedbackPage2.css';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const FeedbackPage2 = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultEmail = searchParams.get('email') || '';
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    age: '',
    country: '',
    email: defaultEmail,
    usecase: '',
  });
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Object.values(formData).some(item => item.trim() === '')) {
      alert('All fields must be filled out.');
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(formData.email)) {
      alert('Invalid email.');
      return;
    }

    // API call
    fetch(process.env.REACT_APP_API_URL + 'submit_feedback2/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      // Handle Success
      setFeedbackSubmitted(true);
    })
    .catch(error => {
      console.error('Error:', error);
      // Handle Error
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
        { feedbackSubmitted ? (
            <div className="success-message-div">
                <p className='centered-text'> Your feedback has been successfully submitted. Thank you! </p>
                {/* A button back to home */}
                <button onClick = {() => navigate('/')}> Back Home </button>
            </div>
    ) : (
            <div className="feedback-container-page2">
                <h3>1:1 Feedback Session</h3>
                <form className="compact-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="fname">First Name:</label>
                        <input type="text" id="fname" name="fname" value={formData.fname} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="lname">Last Name:</label>
                        <input type="text" id="lname" name="lname" value={formData.lname} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="age">Age:</label>
                        <input type="number" id="age" name="age" value={formData.age} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="country">Country:</label>
                        <input type="text" id="country" name="country" value={formData.country} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="usecase">Main Use Case:</label>
                        <textarea 
                            id="usecase" 
                            name="usecase" 
                            value={formData.usecase} 
                            onChange={handleChange} 
                            placeholder='I use the app for work / I find it useful for '>
                        </textarea>
                    </div>
                    <button type="submit" id = 'feedback-page-2-button'>Submit</button>
                </form>
            </div>
        )}
    </>
  )
};

export default FeedbackPage2;
