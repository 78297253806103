import React from 'react';
import './Footer.css'; // Don't forget to create a corresponding CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <p>© 2023 Hotcheck. All rights reserved.</p>
      <p> Created by <a href = 'https://www.santygegenschatz.com'> Santy Gegenschatz </a> </p>
    </footer>
  );
}

export default Footer;
