import React, { useState } from 'react';
import './Banner.css';

const Banner = ({ text }) => {
  const [feedbackSent, setFeedbackSent] = useState(false);

  const sendFeedback = (feedbackType) => {
    // Hide the buttons
    setFeedbackSent(true);

    // Send feedback to server
    fetch(process.env.REACT_APP_API_URL + 'submit_feedback/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ feedback: feedbackType }),
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };

  return (
    <div className='banner'>
      <p>{text}</p>
      {!feedbackSent && (
        <div className='banner-button-container'>
          <button 
            className='thumbs-up'
            onClick={() => sendFeedback('positive feedback')}>
            👍
          </button>
          <button 
            className='thumbs-down'
            onClick={() => sendFeedback('negative feedback')}>
            👎
          </button>
        </div>
      )}
    </div>
  );
};

export default Banner;