import React, { useState, useEffect } from 'react';
import './ImageUploader.css';
// Import the FeedbackComponent
import FeedbackComponent from '../FeedbackComponent/FeedbackComponent';
import ShareComponent from '../ShareComponent/ShareComponent';
import ResultComponent from '../ResultComponent/ResultComponent';

const ImageUploader = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [componentImage, setComponentImage] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [shouldScintillate, setShouldScintillate] = useState(false);
  const [resultCount, setResultCount] = useState(-1);
  const [loadingStatus, setLoadingStatus] = useState([false, false, false, false]);
  const [timesTaken, setTimesTaken] = useState([0, 0, 0, 0]);
  const categories = ['process_cat1/', 'process_cat2/', 'process_cat3/', 'process_cat4/', 'process_cat5/'];
  const category_names = ['Beauty Factor', 'Mood Factor', 'Celebrity Factor', 'Age Factor', 'Style Factor']
  const statusMessages = ['Working on it...', 'Processing image...', 'This may take some seconds...', 'Parsing...', 'Almost there...', 'Up to 30 secs depending on wifi...', 'Almost there, analyzing matches...', 'Faster version coming soon...'];

  const handleImageSelection = (event) => {
    setSelectedImage(URL.createObjectURL(event.target.files[0]));
    setImageFile(event.target.files[0]);
  };

  const handleImageUpload = async () => {
    if (!imageFile) {
      alert('Please select an image to submit first!');
      return;
    }
    setLoadingStatus([false, false, false, false]);
    setTimesTaken([0, 0, 0, 0]);
    setComponentImage(imageFile);
    setShowResults(true);
    scrollToBottom();
  };

  const scrollToBottom = () => {
    if (window.innerWidth <= 768) {  
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  const updateLoadingStatus = (index) => {
    setLoadingStatus(prevStatus => {
      const newStatus = [...prevStatus];
      newStatus[index] = true;
      return newStatus;
    });
  };

  useEffect(() => {
    if (loadingStatus.every(status => status === true)) {
      // This means that all the results have finished loading
      setResultCount(prevCount => prevCount + 1);
      setLoadingStatus([false, false, false, false]);
      // Log complete request with the timestaken
    }
  }, [loadingStatus]);  

  const calculateApiTime = (index, time) => {
    console.log('Calculating time taken for index', index, 'with time', time);
    setTimesTaken(prevTimes => {
      const newTimes = [...prevTimes];
      newTimes[index] = time;
      return newTimes;  // You were missing this line
    });
  };  

  // Use effect once all the times have been logged
  useEffect(() => {
    // Check if all 4 are non 0
    if (timesTaken.every(time => time !== 0)) {
      // Send the times Taken calling the function
      logCompleteRequest(timesTaken);
      setTimesTaken([0, 0, 0, 0]);
    }
  }, [timesTaken]);

  // Call this function when you want to start the sequence
  const triggerScintillation = () => {
    // Start scintillation after 5 seconds
    setTimeout(() => {
      setShouldScintillate(true);

      // Stop scintillation 9 seconds after it starts, which is at 14 seconds from now
      setTimeout(() => {
        setShouldScintillate(false);
      }, 7000);

    }, 4500);
  };

const logCompleteRequest = (timeTaken) => {
  // Call the backend so that the backend saves the number of complete requests
  const data = { maxTimes: timeTaken };
  fetch(process.env.REACT_APP_API_URL + 'log_complete_request/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

  return (
    <div className="image-uploader-container">
      <p className='centered-text'> HotCheck is your gateway to uncovering your allure. </p>
      <p className='centered-text'> Upload a photo of yourself and we'll tell you how good you're looking in it.</p>
      <p className='centered-text'> We'll also tell you other fun info about your pic.</p>
      <div className = 'grid-container'>
        <div className="image-pane">
          <div className="file-input-container">
            <input id="fileInput" type="file" accept="image/*" onChange={handleImageSelection} style={{display: 'none'}} />
            <label htmlFor="fileInput" className="file-input-label">
              {selectedImage ? 'Click to select a different image' : 'Click to select an image'}
              {selectedImage && <img src={selectedImage} alt="Selected" className="selected-image" />}
            </label>
          </div>
          <button className='submit-button-main' onClick={handleImageUpload}> <strong> Submit </strong></button>
          {/* The Share Component */}
          <ShareComponent shouldScintillate={shouldScintillate}/>
        </div>

        <div className="results-pane">
          <h3>Results</h3>
          {/* Check if we should show the results */}
          {showResults && (
            <div className="results-container">
              {categories.map((category, index) => (
                <ResultComponent 
                  key={index} 
                  title={category_names[index]}
                  imageFile={componentImage}
                  endpoint={category}
                  color={index % 2 === 0 ? "red" : "green"}
                  statusMessages={statusMessages}
                  updateLoadingStatus={() => updateLoadingStatus(index)}
                  calculateTimeTaken={calculateApiTime}
                  index={index}
                />
              ))}
              
              {/* Only show the feedback component after it has finished loading */}
              {(resultCount % 5 === 0) && <FeedbackComponent/>}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageUploader;
