import React, { useState } from 'react';
import './FeedbackPage.css';

const FeedbackPage = () => {
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const submitFeedback = async () => {
    if (!feedback.trim()) {
      setStatusMessage('Feedback cannot be empty. Please provide your feedback.');
      return;
    }

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + 'submit_feedback/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ feedback }),
      });

      if (response.ok) {
        setStatusMessage('Thank you for your feedback! Your input helps us improve.');
        setFeedbackSubmitted(true);
      } else {
        setStatusMessage('Something went wrong. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatusMessage('Something went wrong. Please try again later.');
    }
  };

  const submitEmail = async () => {
    // Regex pattern for email validation
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (email && !emailPattern.test(email)) {
      setStatusMessage('Please enter a valid email address.');
      return;
    }
  
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + 'submit_email/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
  
      if (response.ok) {
        setStatusMessage('Thank you for providing your email address.');
      } else {
        setStatusMessage('Something went wrong. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatusMessage('Something went wrong. Please try again later.');
    }
  };
  
  

  return (
    <div className="feedback-container-page">
      <h3>Your Feedback</h3>
      <p>We are doing our best to create a good product.</p>
      <p>We'd like to have your feedback.</p>
      <textarea className="wider-textarea" value={feedback} onChange={handleFeedbackChange} placeholder="I'd like to have more categories. For example ..." disabled={feedbackSubmitted}></textarea>
      <button className="submit-button" onClick={submitFeedback} disabled={feedbackSubmitted}>Submit</button>
      {feedbackSubmitted && (
        <div className="email-container">
          <input type="email" value={email} onChange={handleEmailChange} placeholder="Enter your email (optional)" />
          <button className = "email-submit-button" onClick={submitEmail}>Submit Email</button>
        </div>
      )}
      {statusMessage && <p>{statusMessage}</p>}
    </div>
  );
};

export default FeedbackPage;
