import React, { useState, useEffect } from 'react';
// Import the css stylesheet
import './FeedbackComponent.css';
// Import the useNavigate hook
import { useNavigate } from 'react-router-dom';

const phrases = [
  "Golden Ratio? Facial features factor?",
  "Facial features factor?",
  "Viral Factor?, Fitness Factor?",
  "Professional Factor?",
  "Fitness Factor? Social Media Factor?",
  "Dating Factor? Geo Factor?",
  // add more phrases here
];

const FeedbackComponent = () => {
  const [showComponent, setShowComponent] = useState(true);
  const [feedback, setFeedback] = useState('');
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [email, setEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [currentPhrase, setCurrentPhrase] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * phrases.length);
    const selectedPhrase = phrases[randomIndex];
    setCurrentPhrase(selectedPhrase);
  }, []);

  const handleFeedbackChange = (event) => {
      setFeedback(event.target.value);
  } 

  const handleFeedbackSubmit = () => {
    if (feedback.trim() === '') {
      alert('Please enter your feedback.');
      return;
    }
  
    // Truncate or use all of the placeholder phrase
    // const truncatedPhrase = currentPhrase.length > 10 ? currentPhrase.substring(0, 10) : currentPhrase;
  
    // Merge feedback with the truncated phrase
    const mergedFeedback = `${feedback} | ${currentPhrase}`;
  
    // Send feedback to server
    fetch(process.env.REACT_APP_API_URL + 'submit_feedback/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ feedback: mergedFeedback }),
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      setFeedbackSent(true);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };
  
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailSubmit = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
    if (!emailRegex.test(email)) {
      alert('Please enter a valid email.');
      return;
    }
  
    // Send email to server (replace with your actual logic)
    // Block the button to prevent oversending

    fetch(process.env.REACT_APP_API_URL + 'submit_email/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      // Do anything else after email is submitted, e.g., show the modal
      setShowComponent(false);
      setShowModal(true);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  };
  

  return (
    <>
        {showComponent && (
            <div className='feedback-component-container' style={{ backgroundColor: feedbackSent ? '#4caf50' : '' }}>
                {!feedbackSent ? (
                    <>
                        <p> <strong> Which other categories  </strong> </p>
                        <p> <strong> would you like to see? </strong> </p>
                        <br/>

                        <input type="text" value={feedback} onChange={handleFeedbackChange} placeholder={currentPhrase} id = 'feedback-input' />
                        <button onClick={handleFeedbackSubmit}>Submit</button>
                    </>
                ) : (
                    <>
                        <p> <strong> Thanks! </strong></p>
                        <p> Enter your email for</p>
                        <p>  access to <strong> Beta Features </strong> </p>
                        <br/>
                        <input type="email" placeholder="Email" value={email} onChange={handleEmailChange} id='feedback-input' />
                        <button onClick={handleEmailSubmit}>Submit Email</button>
                    </>
                )}
            </div>
        )}  
        {showModal && (
            <div style={{position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 9999, borderRadius: '12px'}}>
                <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', backgroundColor: 'white', width: '300px', height: '350px', borderRadius: '12px'}}>
                    <h2 id ='thankyou-h2'>Thank You ❤️ </h2>
                    <p className='black-text centered-text'> We're doing our best to to make a products <strong> users like you love </strong></p>
                    <br/>
                    <p className='black-text centered-text'> And we believe the only way to do it is by being at your service and hearing you feedback</p>
                    <br/>
                    <p className='black-text centered-text'> So, if you'd like to participate in a 1:1 feedback session and tell us what features are missing, you're invited to register below:</p>
                    <br/>
                    {/* Two button, one for register and one for closing the modal */}
                    <div className = 'row-centered'>
                        <button className='close-button' onClick={() => setShowModal(false)}> Close </button>
                        <button className='register-button' onClick={() => navigate(`/feedback2?email=${email}`)}> Register </button>
                    </div>
                </div>
            </div>
        )}  
    </>
  )
}

export default FeedbackComponent